import { useRef, FormEvent, FormEventHandler, RefObject } from "react";
import { Trans, useTranslation } from "react-i18next";
import { styled } from "@mui/styles";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

import { PrimaryButton } from "@/components/atoms/Buttons/Buttons";
import { useSendMailMutation } from "@/generated/graphql";
import { H6 } from "@/system/atoms/Typography";

const WhiteBorderTextField = styled(TextField)({
	"& label, & label.Mui-focused": {
		color: "white",
	},
	"& .Mui-focused": {
		"&::after": {
			borderBottom: "solid white 2px !important",
		},
	},
	"& .MuiInput-root": {
		color: "white",

		"&::before": {
			borderBottom: "solid white 1px",
		},

		"&.Mui-focused fieldset": {
			borderColor: "white",
		},
	},
});

const submit = (
	formRef: RefObject<HTMLFormElement>,
	onSubmit: (args: { subject: string; text: string }) => Promise<void>,
): FormEventHandler<HTMLFormElement> => {
	return async (
		event: FormEvent<HTMLFormElement> & {
			target: { subject: { value: string }; text: { value: string } };
		},
	) => {
		event.preventDefault();

		try {
			await onSubmit({ subject: event.target.subject.value, text: event.target.text.value });

			formRef.current?.reset();
		} catch (error) {
			console.error(error);
		}
	};
};

const Send = () => {
	const { t } = useTranslation();
	const [sendMail] = useSendMailMutation();
	const formRef = useRef<HTMLFormElement>(null);

	const onSubmit = async (data: { subject: string; text: string }) => {
		try {
			await sendMail({
				variables: {
					subject: data.subject ?? "Kein Betreff",
					text: data.text,
				},
			});
		} catch (error) {
			console.error("Send", { error });
		}
	};

	return (
		<form onSubmit={submit(formRef, onSubmit)} ref={formRef}>
			<H6 as="h2" color="white">
				<Trans t={t} i18nKey="drawer.sendHeadline">
					We are looking forward to <i>feedback</i>
				</Trans>
			</H6>

			<Box my={5} />

			<WhiteBorderTextField
				variant="standard"
				label={t("drawer.subject").toString()}
				name="subject"
				fullWidth
			/>

			<Box my={3} />

			<WhiteBorderTextField
				variant="standard"
				label={t("drawer.message").toString()}
				name="text"
				fullWidth
				multiline
				rows={5}
			/>

			<Box my={5} />

			<PrimaryButton fullWidth>
				<b>{t("drawer.send").toString()}</b>
			</PrimaryButton>
		</form>
	);
};

export default Send;
