export const translations = {
	back: "Zurück",
	callDirectly: "Direkt anrufen",
	situationStepSubtitle:
		"Danke! Was ist vorgefallen? Nach der folgenden Auswahl wirst du direkt mit dem Awareness-Team verbunden.",
	waitingMessage:
		"Bitte warte einen Moment, das Team wurde benachrichtigt und meldet sich sofort bei dir.",
	automaticUnavailableMessage:
		"Hey, saferspaces ist hier gerade nicht im Einsatz. Wenn du Hilfe benöchtigst wende dich bitte direkt an das Personal vor Ort.",
	waitingHeadline: "Wir sind gleich bei dir",
	waitingMessageAccepted: "Jemand vom Team macht sich jetzt auf den Weg und ist gleich bei dir.",
	waitingHeadlineAccepted: "Wir kommen zu dir",
	automaticFirstMessage:
		"Hey, ich bin auf dem Weg zu dir, gibt es etwas, dass du mir mitteilen möchtest?",
	immediateHelp: "Ich brauche Hilfe",
	geoLocation: {
		modal: {
			denied: {
				headline: "Wir benötigen deinen Standort",
				text: "Bitte erlaube uns deinen Standort zu verwenden. Dies ist notwendig, um herauszufinden, ob in deiner Nähe ein Team zur Unterstützung im Einsatz ist. Gehe in die Einstellungen deines Browsers und erlaube uns deinen Standort zu verwenden.",
			},
			prompt: {
				headline: "Wir benötigen deinen Standort",
				text: "Bitte erlaube uns deinen Standort zu verwenden. Dies ist notwendig, um herauszufinden, ob in deiner Nähe ein Team zur Unterstützung im Einsatz ist.",
			},
		},
		permission: {
			denied: {
				headline: "Wir sind gleich für dich da",
				text: "",
			},
			prompt: {
				headline: "Wir sind gleich für dich da",
				text: "",
			},
			requesting: {
				headline: "Wir sind gleich für dich da",
				text: "Dein Standort wird abgefragt. Bitte warte einen Moment.",
			},
			granted: {
				headline: "Wir sind gleich für dich da",
				text: "Bitte warte kurz, wir suchen nach einem Team in deiner Nähe.",
			},
			error: {
				headline: "Die Standortbestimmung ist fehlgeschlagen",
				text: "Dein aktueller Standort konnte nicht ermittelt werden. Dies kann daran liegen, dass dein Browser keine Standortabfrage unterstützt oder du die Abfrage abgelehnt hast. Bitte versuche es erneut oder wende dich direkt an das Personal vor Ort.",
			},
		},
	},
	general: {
		ok: "Ok",
		retry: "Erneut versuchen",
		sending: "Wird abgeschickt",
	},
	role: {
		headline: "Wir sind für dich da",
		subtitle: "Hilf' uns noch bitte schnell die Situation besser einschätzen zu können",
		victim: "Ich bin Betroffene:r",
		witness: "Ich bin Beobachter:in",
	},
	situation: {
		staff: {
			subtitle:
				"Danke! Was ist vorgefallen? Nach der folgenden Auswahl wirst du direkt mit dem Awareness-Team verbunden.",
			discriminated: "Jemand wird diskriminiert",
			attacked: "Jemand wird angegriffen",
			harassed: "Jemand wird belästigt",
			somethingWrong: "Etwas anderes",
		},
		victim: {
			subtitle:
				"Danke! Was ist vorgefallen? Nach der folgenden Auswahl wirst du direkt mit dem Awareness-Team verbunden.",
			discriminated: "Ich wurde diskriminiert",
			attacked: "Ich wurde angegriffen",
			harassed: "Ich wurde belästigt",
			somethingWrong: "Etwas anderes",
		},
		witness: {
			subtitle:
				"Danke! Was ist vorgefallen? Nach der folgenden Auswahl wirst du direkt mit dem Awareness-Team verbunden.",
			discriminated: "Jemand wird diskriminiert",
			attacked: "Jemand wird angegriffen",
			harassed: "Jemand wird belästigt",
			somethingWrong: "Etwas anderes",
		},
		unknown: {
			subtitle:
				"Danke! Was ist vorgefallen? Nach der folgenden Auswahl wirst du direkt mit dem Awareness-Team verbunden.",
			discriminated: "Jemand wird diskriminiert",
			attacked: "Jemand wird angegriffen",
			harassed: "Jemand wird belästigt",
			somethingWrong: "Etwas anderes",
		},
		somethingWrong: "Etwas anderes",
	},
	qrCodeInternalChat: {
		request: "Das Awareness Team wurde benachrichtigt",
		response: "Jemand ist auf dem Weg",
	},
	confirm: {
		headline: "Wir sind für dich da",
		subtitle:
			"Wenn du den Button klickst, wird unser Awareness Team über deinen aktuellen Standort informiert",
		feedback: "Feedback geben",
		button: "Ich brauche Hilfe",
	},
	drawer: {
		homepage: "Zur Startseite",
		aboutUs: "Über uns",
		headline: "Für ein <1>sichereres</1> Miteinander",
		textLine1:
			"Saferspaces schlägt eine Brücke zwischen Menschen die unsichere Situationen erleben oder beobachten und den Menschen, die vor Ort für das Wohlbefinden aller im Einsatz sind.",
		textLine2:
			"Um die Anwendung zu nutzen, muss nicht extra eine App heruntergeladen werden, da die Kontaktaufnahme über das Scannen von QR-Codes funktioniert. Durch die Nutzung der Anwendung wird der oft herausfordernde Schritt, aktiv auf eine Person zuzugehen und um Hilfe zu bitten, niedrigschwellig und zielgerichtet erleichtert.",
		textLine3: "Zusammen für ein respektvolles Miteinander, lieben Dank & <1>stay safe!</1>",
		textLine4: "Saferspaces",
		sendHeadline: "Wir freuen uns über <1>Feedback</1>",
		subject: "Betreff",
		message: "Nachricht",
		send: "Absenden",
	},
	home: {
		title: "Hier ist leider kein Team im Einsatz",
		description:
			"Bitte wende dich an Menschen in deiner Nähe. Wir hoffen sehr, dass dir unmittelbar geholfen werden kann. Im Falle eines Notfalls, wähle die kostenlose europäische Notrufnummer 112.",
		feedback:
			"Wenn du einen Vorfall im Rahmen der UEFA EURO 2024 melden möchtest, kannst du dies über den folgenden Button tun.",
		button: "Vorfall melden",
	},
	lastVisitedChat: {
		text: "Du warst anscheinend eben bereits in einer Unterhaltung. Möchtest du zu dieser zurückkehren?",
		yes: "Ja",
		no: "Nein",
	},
	venue: {
		inactiveTitle: "Hi!",
		inactiveText:
			"Saferspaces ist hier gerade nicht im Einsatz. Solltest du Hilfe benötigen, wende dich bitte direkt an das entsprechende Security oder Awareness Personal.",
		chat: {
			send: "Nachricht abschicken",
			subTitle: "Das Team hat deine Nachricht erhalten.",
		},
	},
	qrCode: {
		qrCodeAnonymous: {
			errorHeadline: "Ein <1>Fehler</1> ist aufgetreten",
			errorMessage:
				"Bitte wende dich an Menschen in deiner Nähe. Wir hoffen sehr, dass dir unmittelbar geholfen werden kann. Im Falle eines Notfalls, wähle die kostenlose europäische Notrufnummer 112.",
			errorUserFeedbackMessage:
				"Wir wurden über den Fehler informiert. Du kannst uns aber helfen, den Fehler genauer nachzuvollziehen. Beschreiben dafür, was genau du vor dem Auftreten des Fehlers gemacht hast:",
			qrCodeDynamic: {
				errorSubmit:
					"Ein Fehler ist aufgetreten. Bitte versuche es gleich nochmal oder kontaktiere uns unter support@saferspaces.io",
				successSubmit: "Vielen Dank!",
			},
		},
		qrCodeInternalChat: {
			headline: "Brauchst du <1>Support?</1>",
			hint: "Drücke den Button, um das Awareness Team zu benachrichtigen. Ort: ",
			sendSevere: "Ich brauche Hilfe",
			send: "Etwas melden",
			confirmationText: "Du befindest dich gerade hier",
			confirm: "Bestätigen",
		},
	},
	inactiveQRCode: {
		headline: "Dieser Code ist gerade nicht aktiv",
		subtitle:
			"Bitte wende dich an Menschen in deiner Nähe. Wir hoffen sehr, dass dir unmittelbar geholfen werden kann. Im Falle eines Notfalls, wähle die kostenlose europäische Notrufnummer 112.",
	},
	inactive: {
		headline: "Hier ist aktuell niemand im Einsatz",
		subtitle:
			"Bitte wende dich an Menschen in deiner Nähe. Wir hoffen sehr, dass dir unmittelbar geholfen werden kann. Im Falle eines Notfalls, wähle die kostenlose europäische Notrufnummer 112.",
		feedback: "Zum Feedback",
		munichText: "Wenn du die Krisendienste Bayern kontaktieren möchtest, klicke hier",
		munichButton: "Krisendienste Bayern",
	},
} as const;
