export const translations = {
	back: "Back",
	callDirectly: "Call directly",
	personStepHeadline: "We're here for you",
	personStepSubtitle: "Please help us with some details of the situation",
	situationStepSubtitle:
		"Thank you! What exactly happened? After the following selection you will be connected directly to the awareness team.",
	waitingMessage:
		"Please wait a moment, the awareness team has been notified and will respond in a minute.",
	waitingHeadline: "We will respond shortly",
	waitingHeadlineAccepted: "We are on the way",
	waitingMessageAccepted:
		"Someone from awareness team is on their way now and will be right with you.",
	automaticUnavailableMessage:
		"Hey, currently saferspaces is not active here. Please get in contact with the staff nearby.",
	automaticFirstMessage: "Hey, nice to hear from you. How can I help you?",
	immediateHelp: "I need immediate help",
	geoLocation: {
		modal: {
			denied: {
				headline: "We need your location",
				text: "Please allow us to use your location. This is necessary to find out if there is a support team nearby. Go to your browser settings and allow us to use your location.",
			},
			prompt: {
				headline: "We need your location",
				text: "Please allow us to use your location. This is necessary to find out if there is a support team nearby.",
			},
		},
		permission: {
			denied: {
				headline: "We'll be right there for you",
				text: "",
			},
			prompt: {
				headline: "We'll be right there for you",
				text: "",
			},
			requesting: {
				headline: "We'll be right there for you",
				text: "Your location is being requested. Please wait a moment.",
			},
			granted: {
				headline: "We'll be right there for you",
				text: "Please wait a moment, we are looking for a team nearby.",
			},
			error: {
				headline: "The location determination failed",
				text: "Your current location could not be determined. This may be because your browser does not support a location request or you have rejected the request. Please try again or contact the staff on site directly.",
			},
		},
	},
	general: {
		ok: "Ok",
		retry: "Try again",
		sending: "Sending",
	},
	role: {
		headline: "We're here for you",
		subtitle: "Please help us with some details of the situation",
		victim: "I am affected",
		witness: "I am a witness",
	},
	situation: {
		staff: {
			subtitle:
				"Thank you! What exactly happened? After the following selection you will be connected directly to the awareness team.",
			attacked: "Somebody got attacked",
			harassed: "Somebody got harassed",
			somethingWrong: "Something else",
		},
		victim: {
			subtitle:
				"Thank you! What exactly happened? After the following selection you will be connected directly to the awareness team.",
			attacked: "I have been attacked",
			harassed: "I have been harassed",
			somethingWrong: "Something else",
		},
		witness: {
			subtitle:
				"Thank you! What exactly happened? After the following selection you will be connected directly to the awareness team.",
			attacked: "Somebody got attacked",
			harassed: "Somebody got harassed",
			somethingWrong: "Something else",
		},
		unknown: {
			subtitle:
				"Thank you! What exactly happened? After the following selection you will be connected directly to the awareness team.",
			attacked: "Somebody got attacked",
			harassed: "Somebody got harassed",
			somethingWrong: "Something else",
		},
		somethingWrong: "Something else",
	},
	qrCodeInternalChat: {
		request: "The awareness team has been notified",
		response: "Somebody is on the way",
	},
	confirm: {
		headline: "We're here for you",
		subtitle:
			"By clicking the button, the awareness team will be notified with your current location",
		feedback: "Give feedback",
		button: "Immediate help",
	},
	drawer: {
		homepage: "Homepage",
		aboutUs: "About us",
		headline: "Let’s take <1>care</1>!",
		textLine1:
			"Saferspaces connects people who experience or observe unsafe situations with the people on site who are there to support those affected.",
		textLine2:
			"There is no need to download an app to get in touch with the trained staff/ Awareness Teams,  contact can easily be made by scanning the saferspaces QR codes. By using saferspaces, the often challenging step of actively approaching an unknown person and asking for help is made easier in a low-threshold and targeted manner.",
		textLine3: "For a <1>respectful</1> togetherness!",
		textLine4: "Saferspaces",
		sendHeadline: "Thanks for your <1>feedback</1> concerning our application",
		subject: "Subject",
		message: "Message",
		send: "Send",
	},
	lastVisitedChat: {
		text: "You haven been in contact with the awareness team recently. Do you want to continue the conversation?",
		yes: "Yes",
		no: "No",
	},
	venue: {
		inactiveTitle: "Hi!",
		inactiveText:
			"Currently, saferspaces is not active here. If you need assistance, please contact the security or awareness team directly.",
		chat: {
			send: "Send message",
			subTitle: "The team has received your message.",
		},
	},
	home: {
		title: "There is no one on duty here",
		description:
			"We hope that you receive help as quickly as possible. Please reach out to people in your surrounding. In case of an emergency, dial 112, the European emergency number free of charge to receive assistance.",
		feedback:
			"If you want to report an incident in the context of UEFA EURO 2024, you can do so using the following button.",
		button: "Report incident",
	},
	qrCode: {
		qrCodeAnonymous: {
			errorHeadline: "An <1>error</1> occured",
			errorMessage:
				"We hope that you receive help as quickly as possible. Please reach out to people in your surrounding. In case of an emergency, dial 112, the European emergency number free of charge to receive assistance.",
			errorUserFeedbackMessage:
				"We have been informed, that an error occurred. You can help us to find out more about the error by providing some details of what you did immediately before:",
			qrCodeDynamic: {
				errorSubmit:
					"An <1>error</1> occured. Please try again or contact us at support@saferspaces.io",
				successSubmit: "Thanks!",
			},
		},
		qrCodeInternalChat: {
			headline: "Do you need <1>support?</1>",
			hint: "Press the button to notify the awareness team. Location: ",
			sendSevere: "Immediate help",
			send: "Report something",
			confirmationText: "You are currently here",
			confirm: "Confirm",
		},
	},
	inactiveQRCode: {
		headline: "This code is currently not active",
		subtitle:
			"We hope that you receive help as quickly as possible. Please reach out to people in your surrounding. In case of an emergency, dial 112, the European emergency number free of charge to receive assistance.",
	},
	inactive: {
		headline: "Currently there is no one on duty here",
		subtitle:
			"We hope that you receive help as quickly as possible. Please reach out to people in your surrounding. In case of an emergency, dial 112, the European emergency number free of charge to receive assistance.",
		feedback: "Give feedback",
		munichText: "If you would like to contact the crisis services in Bavaria, click here",
		munichButton: "Crisis services Bavaria",
	},
} as const;
