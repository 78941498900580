import * as Sentry from "@sentry/react";
import { CaptureConsole } from "@sentry/integrations";

import { ENVIRONMENT, NAME, SENTRY_DSN, VERSION } from "./config";

Sentry.init({
	dsn: SENTRY_DSN,
	environment: ENVIRONMENT,
	release: `${NAME}@${VERSION}`,
	integrations: [new CaptureConsole({ levels: ["error"] })],
	autoSessionTracking: false,
	sendDefaultPii: false,
	beforeSend: async (event) => {
		if (ENVIRONMENT === "staging" || ENVIRONMENT === "production") {
			return event;
		} else {
			return null;
		}
	},
});
