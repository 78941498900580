import i18n, { LanguageDetectorModule } from "i18next";
import { initReactI18next } from "react-i18next";

import {
	ISupportedLngs,
	defaultNS,
	fallbackLng,
	ns,
	resources,
	supportedLngs,
} from "./locales/resources";

/**
 * Translation
 */
const languageDetector: LanguageDetectorModule = {
	type: "languageDetector",

	detect: () => {
		let language = "en";

		const searchParams = new URLSearchParams(window.location.search);

		const lang = searchParams.get("lang");

		try {
			if (lang) {
				language = lang;
			} else if (navigator.languages && navigator.languages.length > 0) {
				language = navigator.languages[0].split("-")[0];
			} else {
				language = navigator.language.split("-")[0];
			}
		} catch (error) {
			console.error(error);
		}

		return supportedLngs.includes(language as ISupportedLngs) ? language : "en";
	},

	init: (_services) => {
		//
	},
	cacheUserLanguage: (_code) => {
		//
	},
};

i18n
	.use(languageDetector)
	.use(initReactI18next)
	.init({
		ns,
		defaultNS,
		resources,
		fallbackLng,
		supportedLngs,
		detection: {
			lookupQuerystring: "language",
			order: ["querystring", "cookie", "localStorage", "navigator", "htmlTag"],
		},
		interpolation: {
			escapeValue: false,
		},
		debug: false,
	});

export default i18n;
