import { createTheme, ThemeProvider } from "@mui/material";

import { designTokens } from "./theme.tokens";
import { createShadows } from "./theme.helper";

export { type Theme, useTheme } from "@mui/material/styles";
export { makeStyles, styled } from "@mui/styles";

declare module "@mui/material/styles" {
	interface Palette {
		tertiary: Palette["primary"];
	}

	interface PaletteOptions {
		tertiary: PaletteOptions["primary"];
	}
}

export const colorTheme = createTheme({
	palette: {
		primary: {
			main: designTokens.colors.purple.main,
			contrastText: "#fff",
		},
		secondary: {
			light: designTokens.colors.green.mid,
			main: designTokens.colors.green.main,
			dark: designTokens.colors.green.dark,
			contrastText: "#fff",
		},
		tertiary: {
			main: designTokens.colors.lightBlue.main,
		},
		background: {
			paper: designTokens.colors.lavender.dark, //"rgb(226, 246, 248)",
			default: designTokens.colors.lavender.main,
		},
		divider: designTokens.colors.lavender.dark,
		text: {
			primary: "rgb(0, 0, 0)",
			secondary: "rgb(0, 0, 0)",
		},
	},
	shadows: createShadows,
	shape: {
		borderRadius: 24,
	},
	typography: {
		h1: designTokens.typography.h1,
		h2: designTokens.typography.h2,
		h3: designTokens.typography.h3,
		h4: designTokens.typography.h4,
		h5: designTokens.typography.h5,
		h6: designTokens.typography.h6,
		body1: designTokens.typography.copyLargeHero,
		body2: designTokens.typography.copyLarge,
		subtitle1: designTokens.typography.copySmallHero,
		subtitle2: designTokens.typography.copySmall,
		button: designTokens.button.common,
	},
});

export const finalTheme = createTheme(colorTheme, {
	components: {
		MuiAlert: {
			styleOverrides: {
				message: colorTheme.typography.subtitle2,
				root: {
					display: "flex",
					alignItems: "center",
				},
			},
		},
		MuiRadio: {
			styleOverrides: {
				colorPrimary: {
					"&:active": {
						color: designTokens.colors.green.mid,
					},
				},
			},
		},
		// TODO: Check if both are necessary
		MuiInput: {
			styleOverrides: {
				root: colorTheme.typography.subtitle2,
			},
		},
		MuiInputBase: {
			styleOverrides: {
				root: colorTheme.typography.subtitle2,
			},
		},
		MuiFormLabel: {
			styleOverrides: {
				root: colorTheme.typography.subtitle1,
			},
		},
		MuiButtonBase: {
			defaultProps: {
				disableRipple: true,
			},
		},
		MuiIconButton: {
			styleOverrides: {
				colorInherit: {
					"&:focus": {
						border: `solid 1px ${designTokens.colors.purple.main}`,
						background: colorTheme.palette.secondary.light,
						boxShadow: `inset 0px 0px 0px 2px white`,
						">svg": {
							stroke: "white",
						},
					},
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					fontSize: 18,
				},
				textSizeLarge: {
					borderRadius: designTokens.button.sizes.large.height / 2,
					height: designTokens.button.sizes.large.height,
				},
				textSizeMedium: {
					borderRadius: designTokens.button.sizes.medium.height / 2,
					height: designTokens.button.sizes.medium.height,
				},
				textSizeSmall: {
					borderRadius: designTokens.button.sizes.small.height / 2,
					height: designTokens.button.sizes.small.height,
				},
				text: {
					padding: "0 24px",
					// border: `solid 1px ${designTokens.colors.lavender.main}`,

					"&:focus": {
						border: `solid 1px ${colorTheme.palette.secondary.main}`,
						background: colorTheme.palette.secondary.light,
						boxShadow: `inset 0px 0px 0px 2px white`,
						color: "white",
					},
				},
				containedSizeLarge: {
					borderRadius: designTokens.button.sizes.large.height / 2,
					height: designTokens.button.sizes.large.height,
				},
				containedSizeMedium: {
					borderRadius: designTokens.button.sizes.medium.height / 2,
					height: designTokens.button.sizes.medium.height,
				},
				containedSizeSmall: {
					borderRadius: designTokens.button.sizes.small.height / 2,
					height: designTokens.button.sizes.small.height,
				},
				containedSecondary: {
					transition: "all 0.25s linear",

					"&:focus": {
						border: `solid 1px ${colorTheme.palette.secondary.main}`,
						background: colorTheme.palette.secondary.light,
						boxShadow: `inset 0px 0px 0px 2px white`,
					},

					"&:hover": {
						background: colorTheme.palette.secondary.light,
						border: `none`,
						boxShadow: "none",
					},

					"&:active": {
						background: colorTheme.palette.secondary.dark,
						border: `none`,
						boxShadow: "none",
					},
				},
				outlinedSizeLarge: {
					borderRadius: designTokens.button.sizes.large.height / 2,
					height: designTokens.button.sizes.large.height,
				},
				outlinedSizeMedium: {
					borderRadius: designTokens.button.sizes.medium.height / 2,
					height: designTokens.button.sizes.medium.height,
				},
				outlinedSizeSmall: {
					borderRadius: designTokens.button.sizes.small.height / 2,
					height: designTokens.button.sizes.small.height,
				},
				outlinedPrimary: {
					transition: "all 0.25s linear",
					border: `solid 2px ${colorTheme.palette.primary.main}`,

					"&:focus": {
						border: `solid 1px ${colorTheme.palette.primary.main}`,
						background: colorTheme.palette.secondary.light,
						boxShadow: `inset 0px 0px 0px 2px white`,
						color: "white",
					},

					"&:hover": {
						border: `solid 2px ${colorTheme.palette.secondary.light}`,
						background: "transparent",
						boxShadow: "none",
						color: `${colorTheme.palette.secondary.light}`,
					},

					"&:active": {
						border: `solid 2px ${colorTheme.palette.secondary.dark}`,
						background: "transparent",
						boxShadow: "none",
						color: `${colorTheme.palette.secondary.dark}`,
					},
				},
			},
		},
	},
});

export { ThemeProvider, createTheme };
